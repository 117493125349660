import * as React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import BackgroundImage from "gatsby-background-image"

const Hero = ({ children, image, className = '', title, wide = false, collapse = true }) => {
  return (<div className='component-hero'>
    <BackgroundImage
      Tag="section"
      fluid={image?.fluid}
      backgroundColor={`#040e18`}
      critical={true}
      className={className}
    >
      <Container fluid='xl' className={'text-white '}>
        <Row>
          <Col md={8} lg={6} xl={wide ? 8 : 6}>
            <div className='glass-panel bhk-border my-5 px-3 py-2 p-md-4 d-inline-block d-md-block'>
              <h1 className='mb-0 mb-md-3'>{title}</h1>
              <span className={(collapse ? 'd-none d-md-block':'')}>
              {children}
              </span>
            </div>
          </Col>
        </Row>

      </Container>

    </BackgroundImage>

    <Container fluid='xl' className='mt-3'>
      <Row>
        <Col>
          <span className={(collapse ? 'd-md-none':'d-none')}>
            {children}
          </span>
        </Col>
      </Row>
    </Container>


  </div>)
}

export default Hero
